<template>
  <div class="about">
    <b-container class="py-3">
      <page-title title="Mi ez?">
        <template #action>
          <b-button
            href="https://instagram.com/mitjatsszunk.online"
            target="_blank"
            variant="link"
            title="Mit játsszunk online? az Instagramon"
          >
            <b-icon-instagram color="#cb007d" />
          </b-button>
          <b-button
            href="https://facebook.com/mitjatsszunk.online"
            target="_blank"
            variant="link"
            title="Mit játsszunk online? a Facebookon"
          >
            <b-icon-facebook color="#1876f2" />
          </b-button>
        </template>
      </page-title>
      <b-jumbotron>
        <template #header>
          <h3 class="font-weight-light" style="line-height: 42px">
            Hogy mi is <br class="d-sm-none" />
            pontosan a
          </h3>
          <span class="display-4">
            Mit játsszunk
            <strong>.<span class="text-primary">online</span> </strong>?
          </span>
        </template>
        <p class="mt-4 lead">
          Elsősorban egy egyre bővülő játékgyűjtemény, amelyek között mindenki
          találhat kedvére valót. Az oldalt azért hoztuk létre, hogy
          megmutassuk: egymástól távol sem kell lemondani a társasozás öröméről.
        </p>
      </b-jumbotron>
      <div>
        <b-img
          right
          :src="require('@/assets/illustratious/home.svg?data')"
          class="d-none d-md-block"
        />
        <p>
          Az internet tele van jobbnál jobb, akár hivatalos, akár szabadúszó
          fejlesztők által készített alkalmazásokkal, melyek ügyesen és
          kreatívan adják vissza a valódi, polcról levehető társasjátékok
          élményét. Ezeket próbáljuk összeszedni, és könnyen fogyasztható
          formában tálalni a játszani vágyóknak.
        </p>
      </div>
      <div>
        <p>
          Másod(de nem másodlagos!)sorban egy közösség, ahol a tagok
          megoszthatják egymással játékos élményeiket, új játékokat ajánlhatnak,
          sőt,
          <b-link
            class="card-link"
            href="https://discord.gg/3d4jd6A4C3"
            target="_blank"
          >
            Discord szerverünkön
          </b-link>
          kérdezhetnek, vagy ha éppen egyedül vannak, játszópartnereket is
          kereshetnek.
        </p>
      </div>
    </b-container>
    <div class="bg-light py-5 my-2">
      <b-container>
        <b-row>
          <b-col cols="12" offset-md="1" md="10">
            <b-media class="mb-4">
              <template #aside>
                <b-avatar icon="hand-thumbs-up" variant="primary"
              /></template>
              <h4 class="mb-1">
                <strong>
                  Tetszik, ugye?
                </strong>
              </h4>
              <p class="m-0">
                Akkor máris böngészheted ez eddigi, és folyamatosan frissülő
                játékokat.
              </p>
            </b-media>

            <b-media class="mb-4">
              <template #aside>
                <b-avatar icon="question" variant="primary" />
              </template>
              <h4 class="mb-1">
                <strong>
                  Nem találod a kedvencedet?
                </strong>
              </h4>

              <p class="m-0">
                Töltsd fel bátran, írj róla pár dolgot, csatolj hozzá
                játékszabályt, ajánlót, amit csak akarsz! Ehhez ne felejts el
                bejelentkezni Google fiókoddal.
              </p>
            </b-media>

            <b-media>
              <template #aside>
                <b-avatar icon="exclamation" variant="primary" />
              </template>
              <h4 class="mb-1">
                <strong>
                  Hibát találsz egy játék leírásában?
                </strong>
              </h4>
              <p class="m-0">
                Ne félj módosítást javasolni! A pontosabb infóknak mindenki
                örül.
              </p>
            </b-media>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container class="py-3">
      <p class="">
        Végezetül, ha netán egy barátod felteszi neked a kérdést: Mit játsszunk
        <strong>.online</strong>?, hozd el az oldalra!
      </p>
      <p class="text-right mb-1">
        Jó játékot kívánunk!
      </p>
      <p class="text-right font-italic">
        a fejlesztők
      </p>
      <b-alert show variant="light">
        Appverzió: <strong>{{ buildVersion }}</strong
        >, frissítve ekkor: <strong>{{ buildDate }}</strong>
      </b-alert>
    </b-container>
  </div>
</template>
<script>
import PageTitle from "../components/PageTitle.vue";
export default {
  name: "About",
  components: { PageTitle },
  computed: {
    buildVersion() {
      return new Date(document.documentElement.dataset.buildTimestampUtc)
        .getTime()
        .toString(36);
    },
    buildDate() {
      return new Date(
        document.documentElement.dataset.buildTimestampUtc
      ).toLocaleString(this.$i18n.locale);
    }
  }
};
</script>
